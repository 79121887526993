<template>
  <div class="enigma-scroll-container relative opacity-0">
    <!-- CIRCLE TEXT -->
    <img class="absolute z-20 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2" src="../assets/enigma-scroll/circle-text.svg" alt="">
    <!-- LITTLE DOT -->
    <img class="fixed z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 scale-50 md:scale-100" src="../assets/enigma-scroll/circle.png" alt="">
    <!-- PLAN 1 MIN & DESKTOP -->
    <div class="plan1 absolute z-10 top-0 left-0 w-full h-full transform"></div>
    <!-- TOP IMAGE -->
    <div class="top-scroll-image z-20 fixed w-screen h-screen transition duration-300 opacity-0" :class="{'opacity-100': scrolledTop}">
      <p class="text-quinary text-xl md:text-xxl font-sangbleukingdom absolute left-1/2 transform -translate-x-1/2 top-32 md:top-auto md:bottom-32">Cask</p>
    </div>
    <!-- BOTTOM IMAGE -->
    <div class="bottom-scroll-image z-20 fixed w-screen h-screen transition duration-300 opacity-0" :class="{'opacity-100': scrolledBottom}">
      <p class="text-quinary text-xl md:text-xxl font-sangbleukingdom absolute left-1/2 transform -translate-x-1/2 bottom-32">Blended</p>
    </div>
  </div>
</template>

<script>
import gsap from "gsap"
export default {
  name: 'EnigmaScroll',
  data () {
    return {
      scrolledTop: false,
      scrolledBottom: false,
      container: null,
      parallaxElt: null
    }
  },
  destroyed () {
    window.removeEventListener('scroll', this.scrollMonitoring)
  },
  mounted () {
    this.container = document.getElementsByClassName('enigma-scroll-container')[0]
    this.parallaxElt = document.getElementsByClassName('plan1')[0]
    this.parallaxElt.style.transform = 'translateY('+(window.scrollY/13) +'px)'
    let tlContainer = gsap.timeline()
    tlContainer.to('.enigma-scroll-container', {delay: 0.5, duration: 3, opacity: 1})

    const middleScreen = this.container.offsetHeight / 2 - window.innerHeight / 2
    window.scrollTo(0, middleScreen)

    window.addEventListener('scroll', this.scrollMonitoring);
  },
  methods: {
    scrollMonitoring (event) {
      // Parallax effect
      this.parallaxElt.style.transform = 'translateY('+(Math.floor(window.scrollY/13)) +'px)'

      // scroll to top management
      if (!this.scrolledTop && window.scrollY < 50) {
        console.log('top reached')
        this.scrolledTop = true 
      } else if (this.scrolledTop && window.scrollY >= 50) {
        console.log('top exited')
        this.scrolledTop = false
      }

      // scroll to bottom management
      if (!this.scrolledBottom && window.scrollY > this.container.offsetHeight - window.innerHeight - 100) {
        console.log('bottom reached')
        this.scrolledBottom = true 
      } else if (this.scrolledBottom && window.scrollY <= this.container.offsetHeight - window.innerHeight - 100) {
        console.log('bottom exited')
        this.scrolledBottom = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .enigma-scroll-container {
    width: 100%;
    height: 5353px;
    background: url('../assets/enigma-scroll/plan2-min.jpg') center center no-repeat;
    background-size: 100% 100%;
    @media screen and (min-width: 768px) {
      background: url('../assets/enigma-scroll/plan2.jpg') center center no-repeat;
      background-size: 100% 100%;
    }
    @media screen and (min-width: 1440px) {
      background: url('../assets/enigma-scroll/plan2-max.jpg') center center no-repeat;
      background-size: 100% 100%;
    }
    .plan1 {
      background: url('../assets/enigma-scroll/plan1-min.png') center center no-repeat;
      background-size: 100% 100%;
      @media screen and (min-width: 768px) {
        background: url('../assets/enigma-scroll/plan1.png') center center no-repeat;
        background-size: 100% 100%;
      }
      @media screen and (min-width: 1440px) {
        background: url('../assets/enigma-scroll/middle-branch.png') center center no-repeat, url('../assets/enigma-scroll/left-branch.png') left center no-repeat, url('../assets/enigma-scroll/right-branch.png') right center no-repeat, url('../assets/enigma-scroll/black-block.png') bottom 20% left 10% no-repeat, url('../assets/enigma-scroll/white-block-left.png') top 15% left 10% no-repeat, url('../assets/enigma-scroll/white-block-right.png') top 22% right 10% no-repeat;
        background-size: 850px, 250px, 250px, 1000px, 1000px, 1000px;
      }
      @media screen and (min-width: 2100px) {
        background: url('../assets/enigma-scroll/middle-branch.png') center center no-repeat, url('../assets/enigma-scroll/left-branch.png') left center no-repeat, url('../assets/enigma-scroll/right-branch.png') right center no-repeat, url('../assets/enigma-scroll/black-block.png') bottom 20% left 10% no-repeat, url('../assets/enigma-scroll/white-block-left.png') top 15% left 10% no-repeat, url('../assets/enigma-scroll/white-block-right.png') top 22% right 10% no-repeat;
        background-size: auto, auto, auto, 1000px, 1000px, 1000px;
      }
    }

    .top-scroll-image {
      background: #000 url('../assets/enigma-scroll/cask-min.jpg') center bottom no-repeat;
      background-size: cover;
      @media screen and (min-width: 1200px) {
        background: #000 url('../assets/enigma-scroll/cask.jpg') center top no-repeat;
        background-size: cover;
      }
    }
    .bottom-scroll-image {
      background: url('../assets/enigma-scroll/blended-min.jpg') center center;
      background-size: cover;
      @media screen and (min-width: 768px) {
        background: url('../assets/enigma-scroll/blended.jpg') center center;
        background-size: cover;
      }
    }
  }
</style>